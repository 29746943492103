html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Rubik', sans-serif !important;
  max-width: 192rem;
  margin-left: auto;
  margin-right: auto;  
}
html {
  /* Set 1rem to the browser default (10px) */
  font-size: 62.5%;
  overflow-x: hidden;
}
a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  font-family: 'Rubik', sans-serif !important;
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.border-white {
  border-color: white !important;
}

::-webkit-scrollbar{
  width: 5px;
  height: 6px;
}

::-webkit-scrollbar-track{
  box-shadow: inset 0 0 5px #a5aaad;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb{
  background: #002147;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover{
  background: #a5aaad;
}

/* form input css */

input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
input[type="date"],
textarea {
  display: block;
  width: 100%;
  padding: 0.3rem 3rem;
  font-size: 1.6rem;
  line-height: 2.2rem;  
  font-weight: 400;
  border-radius: 0.4rem;
  border: 1px solid #9FA2B2;
  margin-top: 1rem;  
  resize: vertical;
  outline: none;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
input[type="date"] {
  height: 3.8rem;
}

.form-input-select{
	height: 3.8rem;
  padding: 0 1rem;  
  border: 1px solid #9FA2B2;
  border-radius: 0.4rem;
  outline: none;
  font-size: 1.4rem;
}

::-webkit-input-placeholder {
  color: #858796;
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 2.2rem;  
}

.error {
  color: red;
  position: absolute;
  right: 0;
  font-size: 1.2rem;  
}

.input-error {
  border: 1px solid;
  border-color: red !important;
}

.active > div > div > div{
  color: #fd7e14 !important;  
}

.rotate-180{
  transform: rotate(180deg);
}

.border{
  border: 1px solid #7f7f7f;  
  font-size: 1.6rem;
  font-weight: 500;
  padding: 1rem;
}